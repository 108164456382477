<template>
  <div class="vld-parent"> 
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true" :z-index="9999"/>
    <div class="row">

      <!-- <div class="col-md-6">
        <button class="btn btn-secondary btn-sm float-right" @click="showRetoureModal"><i class="fas fa-redo"></i> Rücksendeetikett hinzufügen</button>
      </div> -->
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h2 class="card-title">Versandetiketten</h2>
          </div>

          <div class="card-body">
            <table class="table table-striped table-sm table-bordered">
              <thead class="thead-light">
                <th>Tracking-Code</th>
                <th>Versandetikett</th>
                <th>Art</th>
                <th>Erstellungsdatum</th>
                <th>Aktionen</th>
              </thead>

              <tbody>
                <tr v-for="label in order.labels" :key="label.id">
                  <td>{{ label.trackingCode }}</td>
                  <td><a target="_blank" :href="url + '/api/v1/download/shipping/' + label.hash">Zeige Versandetikett als PDF</a></td>
                  <td>Versandetikett</td>
                  <td>{{ label.created_at }}</td>
                  <td>
                    <button class="btn btn-sm btn-danger mr-1"><i class="fas fa-trash" @click="deleteLabel(label.id)"></i></button>
                    <a target="_blank" :href="'https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=' + label.trackingCode" class="btn btn-sm btn-secondary"><i class="fas fa-truck"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="parcelModal" title="Neuen Paketschein anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="generateLabel">
       <div class="row">
         <div class="col-md-12">
           <div class="alert alert-danger alert-dismissible" v-if="errors != null">
                  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <h5><i class="icon fas fa-ban"></i> Alert!</h5>
                    <ul>
                      <li v-for="error in errors" :key="error">{{error}}</li>
                    </ul>
                </div>
         </div>
       </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Versanddaten</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Vorname:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.first_name" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Firma:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.company" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Straße:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.street" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Postleitzahl:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.zipcode" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Telefon:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.phone" />
                      </div>
                    </div>


                  </div>

                  <div class="col-md-6">

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Nachname:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.last_name" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Abteilung:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.department" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Hausnummer:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.house_number" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Stadt:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.city" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">E-Mail:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.customer.email" />
                      </div>
                    </div>


                  </div>

                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Paketmaße & -gewicht</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <!-- <div class="col-md-4">
                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Länge (cm):</label>
                      <div class="col-md-7">
                          <input type="number" class="form-control form-control-sm" />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Breite (cm):</label>
                      <div class="col-md-7">
                          <input type="number" class="form-control form-control-sm" />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Höhe (cm):</label>
                      <div class="col-md-7">
                          <input type="number" class="form-control form-control-sm" />
                      </div>
                    </div>
                  </div> -->

                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Gewicht (kg):</label>
                      <div class="col-md-7">
                          <input type="number" class="form-control form-control-sm" value="1" v-model="weight" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Einstellungen</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">DHL Produkt:</label>
                      <div class="col-md-7">
                          <select class="form-control form-control-sm" v-model="selectedProduct">
                            <option v-for="shippingProduct in shippingProducts" :key="shippingProduct.id" :value="shippingProduct.id">{{ shippingProduct.name }}</option>
                          </select>
                      </div>
                    </div>

                    <!-- <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Alterssichtprüfung:</label>
                      <div class="col-md-7">
                          <select class="form-control form-control-sm" >
                            <option selected>Keine</option>
                            <option>Ab 16 Jahre</option>
                            <option>Ab 18 Jahre</option>
                          </select>
                      </div>
                    </div> -->

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Anzahl Etiketten:</label>
                      <div class="col-md-7">
                          <input type="number" class="form-control form-control-sm" value="1" v-model="qty"/>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <!-- <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <strong><label class="form-check-label" for="exampleCheck1">Exportdokument erstellen</label></strong>
                    </div>

                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <strong><label class="form-check-label" for="exampleCheck1">Nachnahme</label></strong>
                    </div>

                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <strong><label class="form-check-label" for="exampleCheck1">Persönliche Übergabe</label></strong>
                    </div>

                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <strong><label class="form-check-label" for="exampleCheck1">Filial Routing</label></strong>
                    </div> -->

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Zusatzinformationen</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group row">
                        <label for="" class="col-md-3 col-form-label">Liefertag:</label>
                        <div class="col-md-7">
                            <input type="date" class="form-control form-control-sm"  />
                        </div>
                      </div>
                  </div> 

                  <div class="col-md-6">
                      <div class="form-group row">
                        <label for="" class="col-md-3 col-form-label">Ablageort:</label>
                        <div class="col-md-7">
                            <input type="text" class="form-control form-control-sm" />
                        </div>
                      </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
           
                      <div class="form-group row">
                          <label for="" class="col-md-3 col-form-label">Nachbar:</label>
                          <div class="col-md-7">
                              <input type="text" class="form-control form-control-sm" />
                          </div>
                      </div>
                      
                      
                  </div>

                  <div class="col-md-6">
           
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <strong><label class="form-check-label" for="exampleCheck1">Keine Nachbarschaftszustellung</label></strong>
                      </div>
                  
                  </div>
                  
                </div>

                  
                
                
                </div>
            </div>
          </div>
        </div> -->

        
        
    </b-modal>


    <b-modal id="retoureModal" title="Neuen Rücksendeschein anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Versanddaten</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Vorname:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.first_name" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Firma:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.company" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Straße:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.street" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Postleitzahl:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.zipcode" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Telefon:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.phone" />
                      </div>
                    </div>


                  </div>

                  <div class="col-md-6">

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Nachname:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.last_name" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Abteilung:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.department" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Hausnummer:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.house_number" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">Stadt:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.city" />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="" class="col-md-3 col-form-label">E-Mail:</label>
                      <div class="col-md-7">
                          <input type="text" class="form-control form-control-sm" v-model="order.customer.email" />
                      </div>
                    </div>


                  </div>

                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Paketgewicht</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                <div class="form-group row">
                          <label for="" class="col-md-3 col-form-label">Gewicht (kg):</label>
                          <div class="col-md-7">
                              <input type="text" class="form-control form-control-sm" value="1" />
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </b-modal>



  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'OrderDetailsShipping',
  props: [
      'order'
  ],
  components: {
            Loading
        },

  data(){
    return {
      shippingProducts: [],
      selectedProduct: 1,
      qty: 1,
      weight: 1,
      errors: null,
      url: process.env.VUE_APP_DOWNLOAD_URL,
      loading: false,
    }
  },

  methods:{
    generateLabel(bvModalEvt){
      this.loading = true;
      bvModalEvt.preventDefault();

      var data = {
              company_id: this.order.company.id,
              product_id: this.selectedProduct,
              order_id: this.order.id,
              receiver: {
                name: this.order.shipping.first_name + ' ' + this.order.shipping.last_name,
                company: this.order.shipping.company,
                department: this.order.shipping.department,
                street: this.order.shipping.street,
                house_number: this.order.shipping.house_number,
                zipcode: this.order.shipping.zipcode,
                city: this.order.shipping.city
              },
              options: {
                weight: this.weight,
                reference: this.order.ordernumber,
                email: this.order.customer.email
              }
      }

       this.axios
            .post("/shipping/labels/validateShipping", data)
            .then(() => {
                for (let i = 0; i < this.qty; i++)
                {
                    this.axios
                        .post("/shipping/labels", data)
                        .then(() => {
                          
                        })
                        .catch(() => {
                          this.$swal({
                              icon: "error",
                              title: "Oops...",
                              text: "Da ist etwas schief gelaufennnnn",
                          });
                        });
                }
                
                setTimeout(() => {
                  this.$bvModal.hide("parcelModal");
                  this.$parent.getOrder();
                  this.loading = false;
                }, 1000);
            })
            .catch(error => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufennnnn",
                });
                this.errors = error.response.data.error;
            });
    },

    deleteLabel(id){
      this.$swal({
                    title: "Möchtest du das Label wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/shipping/labels/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Label erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.$parent.getOrder();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
    },

    showParcelModal(){
        this.$bvModal.show("parcelModal");
    },

    showRetoureModal(){
        this.$bvModal.show("retoureModal");
    },

    loadShippingProducts(){
        this.axios
            .get("/shipping/products")
            .then(response => {
              this.shippingProducts = response.data.data;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufennnnn",
                });
            });
    },
  },

  created() {
      this.loadShippingProducts();
  }
}
</script>

<style>

</style>